import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../services/storage/local-storage.service';
import { StateService } from '../../services/storage/state.service';
import { StoreGroupResolverResolver } from '../../services/resolver/store-group-resolver.resolver';
import { StoreDetailService } from '../../services/resolver/store-detail.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {
  storeDetail:any
  storeGroups:any;
  storeGroupData:any
  constructor(
    private localStorage: LocalStorageService,
    private storeGroupResolver: StoreGroupResolverResolver,
    private storeDetailResolver:StoreDetailService,
    private dialog:MatDialog
  ) { }

  ngOnInit(): void {
    this.getStoreDetail();
    this.getStoreGroups();
  }

  getStoreDetail() {
    this.storeDetail = this.storeDetailResolver.permissionInit;
  }

  getStoreGroups() {
    this.storeGroups = this.storeGroupResolver.permissionInit?.stores_detail;
  }

}
