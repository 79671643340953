<div class="navigation">
  <div class="overlay-desktop"></div>
  <div class="container">
    <div class="mobile-nav">
      <div class="row-nav-mobile">
        <a [routerLink]="['/']" *ngIf="storeDetail?.logo" (click)="closeMenu()">
          <img class="logo-mobile" [src]="storeDetail?.logo" [alt]="storeDetail?.name" />
        </a>
        <ng-container 
            [ngTemplateOutlet]="searchTemplate" 
            [ngTemplateOutletContext]="{isMobile:true}">
        </ng-container>
        <div class="right-row">
          <!-- <div class="icons language" (click)="openOptions('language')" *ngIf="!kingVersion">
          <img src={{languageImage}} alt="" class="languageImage" />
          <p class="language-name">{{languageCode}}</p>
            <div class="lang-options" *ngIf="isShowOptions">
              <div *ngFor="let lang of availableLanguages" (click)="changeLanguage(lang.code)" class="countries"> -->
          <!-- <img src="{{lang.img}}"> -->
          <!-- <p class="language-name">{{ lang.str }}</p>
              </div>
            </div>
          </div> -->
          <!-- <img class="menu-icon" src="assets/images/icons/hamburger-munu.svg" (click)="openMenu()" /> -->
          <div class="icons region-language">
            <div class="region-lang-row" (click)="openPrefrences()">
              <img [src]="selectedRegion?.image" alt="" class="region-flag" />
              <p class="language-name">{{selectedRegion?.name}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desktop-nav">
      <div class="nav-row">
        <div class="nav-logo left-row">
          <a [routerLink]="['/']" *ngIf="storeDetail?.logo"> <img [src]="storeDetail?.logo"
              [alt]="storeDetail?.name" /></a>
        </div>
        <div class="middle-row">
          <nav>
            <a [routerLink]="['/']" routerLinkActive="active-tab"
              [routerLinkActiveOptions]="{ exact: true }">{{'nav.home' | translate}}</a>
            <a [routerLink]="['/category']" routerLinkActive="active-tab">{{'nav.categories' | translate}}</a>
            <a [routerLink]="['/pages/contact-us']" routerLinkActive="active-tab">{{'nav.contactUs' | translate}}</a>
            <a [routerLink]="['/profile']" [queryParams]="{tab: 'wishList'}"
              routerLinkActive="active-tab">{{'nav.wishList' | translate}}</a>
          </nav>
          <ng-container 
             [ngTemplateOutlet]="searchTemplate" 
             [ngTemplateOutletContext]="{isMobile:false}">
          </ng-container>
        </div>
        <div class="right-row">
          <!-- <div class="icons language" (click)="openOptions('language')">
            <img src={{languageImage}} alt="" class="languageImage" />
            <p class="language-name">{{languageCode}}</p>
            <div class="lang-options" *ngIf="isShowOptions">
              <div *ngFor="let lang of availableLanguages" (click)="changeLanguage(lang.code)" class="countries">
                <img src="{{lang.img}}">
                <p class="language-name">{{ lang.str }}</p>
              </div>
            </div>
          </div> -->
          <div class="icons region-language">
            <div class="region-lang-row" (click)="openPrefrences()">
              <img [src]="selectedRegion?.image" alt="" class="region-flag" />
              <p class="language-name">{{selectedRegion?.name}}</p>
            </div>
          </div>
          
          <div class="icons" *ngIf="isLoggedIn" [routerLink]="['/profile']" [queryParams]="{tab: 'wallet'}">
            <img src="assets/images/icons/asset7.png" alt="" class="filter-img" />

            <!-- <div class="wallet-options" *ngIf="showBalance">
              <div [routerLink]="['/profile']" class="countries" *ngIf="isLoggedIn" [queryParams]="{tab: 'wallet'}">
                <p>{{'nav.remaining' | translate}}: {{storeDetail?.currency_detail?.symbol}}{{remaining}}</p>
              </div>
            </div> -->

          </div>
          <div class="icons" [routerLink]="['/authenticate/login']" *ngIf="!isLoggedIn">
            <img src="assets/images/icons/asset7.png" alt="" class="filter-img" />
          </div>

          <div class="icons" [routerLink]="['/authenticate/login']" *ngIf="!isLoggedIn">
            <img src="assets/images/icons/account_login.png" class="filter-img" alt="" />

          </div>
          <div class="icons" *ngIf="isLoggedIn" [routerLink]="['/profile']" [queryParams]="{tab:'shopSummary'}">
            <img src="assets/images/icons/account_login.png" alt="" class="filter-img" />
            <!-- <div class="profile-options" *ngIf="isProfileOption">
              <div [routerLink]="['/profile']" [queryParams]="{tab:'shopSummary'}" *ngIf="isLoggedIn" class="item">
                <p *ngIf="profileData?.phone">+{{profileData?.phone}}</p>
                <p *ngIf="!profileData?.phone">{{profileData?.email}}</p>
              </div>
            </div> -->
          </div>

        </div>
      </div>
    </div>
    <div class="mobile-menu" #mobileMenu>
      <img class="close-icon" src="assets/images/icons/back.svg" (click)="closeMenu()" />
      <div class="nav-item">
        <a [routerLink]="['/']" (click)="closeMenu()" routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          <img class="icons" src="assets/images/icons/new-home.svg" alt="" />
          <p>{{'nav.home' | translate}}</p>
        </a>
      </div>
      <div class="nav-item">
        <a [routerLink]="['/category']" (click)="closeMenu()" routerLinkActive="active">
          <img class="icons" src="assets/images/icons/new-categories.svg" alt="" />
          <p>{{'nav.categories' | translate}}</p>
        </a>
      </div>
      <div class="nav-item" *ngIf="isLoggedIn && kingVersion">
        <a [routerLink]="['/profile']" [queryParams]="{tab:'shopSummary'}" (click)="closeMenu()"
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <img src="assets/images/icons/summary.4886af5e51d9.svg" class="summary-icon">
          <p>Order History</p>
        </a>
      </div>
      <div class="nav-item border">
        <a [routerLink]="['/pages/contact-us']" (click)="closeMenu()" routerLinkActive="active">
          <img class="icons" src="assets/images/icons/new-contact-us.png" alt="" />
          <p>{{'nav.contactUs' | translate}}</p>
        </a>
      </div>
      <div class="nav-item" *ngIf="isLoggedIn && !kingVersion">
        <a [routerLink]="['/profile']" (click)="closeMenu()" routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          <img class="icons" src="assets/images/icons/new-profile.svg" alt="" />
          <p>{{'nav.profile' | translate}}</p>
        </a>
      </div>
      <div class="nav-item" *ngIf="isLoggedIn && !kingVersion">
        <a [routerLink]="['/profile']" [queryParams]="{tab: 'wallet'}" (click)="closeMenu()" routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          <img class="icons" src="assets/images/icons/new-wallet.svg" alt="" />
          <p>Wallet</p>
        </a>
      </div>
      <div class="nav-item" *ngIf="!isLoggedIn && !kingVersion">
        <a [routerLink]="['/authenticate/login']" (click)="closeMenu()" routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }">
          <img class="icons" src="assets/images/icons/new-login.png" alt="" />
          <p>{{'nav.login' | translate}}</p>
        </a>
      </div>
      <div class="nav-item" *ngIf="isLoggedIn && !kingVersion">
        <a (click)="logOutUser()">
          <img class="icons" src="assets/images/icons/new-logout.png" alt="" />
          <p>{{'nav.logOut' | translate}}</p>
        </a>
      </div>
      <div class="footer-container" [ngClass]="{'border-top': kingVersion ==false}">
        <div class="footer">
          <a *ngFor="let staticLink of storeDetail?.static_pages" [routerLink]="['pages', staticLink.slug]"
            routerLinkActive="router-link-active" (click)="closeMenu()">
            <p>{{staticLink.title}}</p>
          </a>
        </div>
      </div>
      <div class="social-media" *ngIf="!kingVersion">
        <a *ngFor="let socialLink of storeDetail?.social_links" [href]="socialLink?.address" target="_blank"> <i
            [class]="socialLink?.fontawesome_class"></i></a>
      </div>
    </div>
    <div class="overlay-mobile" (click)="closeMenu()" #mobileOverlay></div>
  </div>
</div>

<ng-template #prefrencesDialog>
  <div class="prefrences-container">
    <p class="title">{{'nav.preferences' | translate}}</p>
    <br>
    <mat-label>{{'nav.region' | translate}}:</mat-label>
    <mat-form-field appearance="outline">
      <mat-select
        *ngIf="filteredCountryList"
        [formControl]="storeRegionSelect"
        class="region-select"
        [panelClass]="'custom-select-panel'"
        (openedChange)="clearSearchField()"
      >
        <mat-select-trigger class="region-row">
          <img [src]="storeRegionSelect?.value?.image" alt="" />
          <p>{{storeRegionSelect?.value?.name}}</p>
        </mat-select-trigger>
          <mat-form-field class="mat-select-search">
            <input
              matInput
              type="text"
              placeholder="Search countries..."
              [(ngModel)]="searchedCountry"
              (ngModelChange)="filterCountries()"
              
              autocomplete="off"
            />
          </mat-form-field>
        <mat-option
          *ngFor="let country of filteredCountryList"
          class="region-row"
          [ngClass]="{'first-opt': filteredCountryList.length === countryList.length}"
          [value]="country"
        >
          <img [src]="country.image" alt="" />
          <p>{{country.name}}</p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    
    <div>
      <span class="hint">{{'nav.attention' | translate}}:
      </span>
      <p class="hint-context"> {{'nav.note' | translate}}</p>
    </div>
    <br>
    <mat-label>{{'nav.language' | translate}}:</mat-label>
    <mat-form-field appearance="outline">
      <mat-select [formControl]="languageControl" class="region-select">
        <mat-option *ngFor="let lang of availableLanguages" class="region-row" [value]="lang">
          <p class="language-name">{{ lang.str }}</p>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="button-container">
      <button mat-raised-button (click)="savePrefrences()" color="primary">{{'nav.savePreferences' |
        translate}}</button>
    </div>
  </div>

</ng-template>

<ng-template #searchTemplate let-isMobile='isMobile'>
  <shared-search-field [isMobile]="isMobile"></shared-search-field>
</ng-template>