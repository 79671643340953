import { isPlatformBrowser } from '@angular/common';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class ErrorLogger implements ErrorHandler {

  constructor(@Inject(PLATFORM_ID) private readonly platformId: any) {
    if(isPlatformBrowser(this.platformId)) {
      Sentry.init({
        dsn: "https://67ad7ad6ea234e7f914fa40e673485eb@o442018.ingest.sentry.io/5878552",
        integrations: [
          new Sentry.BrowserTracing({
            tracingOrigins: ["localhost", "https://yourserver.io/api"],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],
        ignoreErrors: [

        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    }
  }

  handleError(error: any): void {
    if (!error || !isPlatformBrowser(this.platformId)) return;
    
    const errException = this.extractError(error);

    if (errException) {
      Sentry.captureException(errException);
    }
  }
  
  private extractError(error: any): Error | string | undefined {
    if ('detail' in error && error.detail) {
      return error.detail;
    }

    if ('error' in error && error.error) {
      return error.error;
    }

    if ('message' in error && error.message) {
      return error.message;
    }

    if (error instanceof Error) {
      return error;
    }

    return undefined;
  }
}