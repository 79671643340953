<div class="dialog-container">
  <p class="title">{{'cookie.modalTitle' | translate}}</p>
  <!-- <mat-label>{{'nav.region' | translate}}:</mat-label> -->    
  <div class="dialog-main">
    <p class="dialog-main-text">
      {{'cookie.modalDesc' | translate}}
      <a [routerLink]="[ '/pages/cookie-policy']">Cookie Notice</a>
      &      
      <a [routerLink]="[ '/pages/privacy-policy']">Privacy Statement</a>
    </p>
  </div>
  <div class="button-container">
    <button mat-button color="primary" (click)="openSettings()">{{'cookie.settingsBtn' | translate}}</button>
    <button mat-raised-button color="primary" (click)="acceptAll()">{{'cookie.modalAccept' | translate}}</button>
  </div>
</div>

<ng-template #customSettings>
  <div class="dialog-container dialog-large">
    <p class="title">Manage Consent Preferences</p>
    <!-- <mat-label>{{'nav.region' | translate}}:</mat-label> -->    
    <div class="dialog-main">
      <div class="dialog-desc">
        <p class="dialog-main-text">
          We use cookies that ensure a reliable, personalized and safe shopping experience.
        </p>
      </div>
      <div class="expand-container">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Strictly&nbsp;Necessary&nbsp;Cookies
              </mat-panel-title>
              <mat-panel-description>
                <span class="spacer"></span>
                <mat-slide-toggle
                checked="true"
                disabled="true"
                ></mat-slide-toggle>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>These cookies are necessary for the website to function and cannot be switched off</p>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Analytics&nbsp;cookies
              </mat-panel-title>
              <mat-panel-description>
                <span class="spacer"></span>
                <mat-slide-toggle
                (click)="$event.stopPropagation()"
                (change)="toggleCookie()"
                [checked]="analyticsCookie"
                ></mat-slide-toggle>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>These cookies collect information that is used either in aggregate form to help us understand how our websites are being used or how effective our marketing campaigns are.</p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="button-container">
      <!-- <button mat-button color="primary"></button> -->
      <button mat-raised-button color="primary" (click)="confirmCustom()">Confirm My Choices</button>
    </div>
  </div>
</ng-template>

