import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  Router,
  ActivatedRoute,
} from '@angular/router';
import { StoreDetailService } from '../../services/resolver/store-detail.service';
import { LocalStorageService } from '../../services/storage/local-storage.service';
import { StateService } from '../../services/storage/state.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Language } from '../../enum/Language';
import { environment } from 'src/environments/environment';
import { StoreDetail } from '../../models/store-details.model';
import { CountryDetail, Profile } from '../../models/profile.mode';
import { LogoutDialogComponent } from 'src/app/modules/product/components/logout-dialog/logout-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StoreRegionService } from '../../services/store-region.service';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class NavigationComponent implements OnInit {
  storeDetail: StoreDetail;
  selectedLang: String = 'En';
  isLoggedIn: boolean = false;
  isShowOptions: boolean = false;
  storeRegionSelect: FormControl<CountryDetail> = new FormControl();
  languageControl: FormControl = new FormControl();
  languageImage: any = '';
  languageCode: any = ''
  showBalance: boolean = false;
  remaining: any;
  isProfileOption: boolean = false;
  profileData: Profile;
  params: any;
  countryList: CountryDetail[];
  filteredCountryList: CountryDetail[];
  searchedCountry = '';
  selectedRegion: CountryDetail;
  countryIdParam: string;
  prefrencesDialogRef: MatDialogRef<any>;

  availableLanguages = [
    {
      code: 'en',
      str: 'English',
      img: 'assets/images/icons/usa-flag.svg',
      primaryImage: 'assets/images/icons/usa-primary-flag.png',
    },
    {
      code: 'ar',
      str: 'العربية',
      img: 'assets/images/icons/uae-flag.svg',
      primaryImage: 'assets/images/icons/uae-primary-flag.png',
    },
    {
      code: 'tr',
      str: 'Türkçe',
      img: 'assets/images/icons/turkey-flag.svg',
      primaryImage: 'assets/images/icons/turkey.png',
    },
    // {
    //   code: 'fa',
    //   str: 'Fa',
    //   img: 'assets/images/icons/iran.svg',
    //   primaryImage: 'assets/images/icons/iran.png'
    // }
  ];

  @ViewChild('mobileMenu') mobileMenu: ElementRef;
  @ViewChild('mobileOverlay') mobileOverlay: ElementRef;
  @ViewChild('prefrencesDialog') prefrencesDialog: any;
  platformId: Object

  kingVersion:boolean=false

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private stateService: StateService,
    private storeDetailResolver: StoreDetailService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private _eref: ElementRef,
    private storeRegionService: StoreRegionService,
    @Inject(DOCUMENT) private document: Document,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId: Object,
    private googleTagManagerService: GoogleTagManagerService
  ) { 
    this.platformId = platformId
  }

  ngOnInit(): void {
    if(this.isClientPlatform(this.platformId)) {
      if(window['__NEZHA_BRIDGE__']){
        this.kingVersion=true
      }
    }
    this.activatedRoute.queryParams.subscribe(param => {
      this.countryIdParam = param.country || '';
    })
    this.checkUserLanguage();
    this.setLoginStatus();
    this.getStoreDetail();
    this.initRegion();
  }

  filterCountries() {
    const searchTextLower = this.searchedCountry.toLowerCase();
    this.filteredCountryList = this.countryList.filter(country =>
      country.name.toLowerCase().includes(searchTextLower)
    );
  }

  clearSearchField() {
    this.searchedCountry = '';
    this.filteredCountryList = this.countryList;
  }

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.isShowOptions = false;
      this.showBalance = false;
      this.isProfileOption = false;
    }
  }

  checkUserLanguage() {
    let userLang;
    if(this.isClientPlatform(this.platformId)) {
      userLang = this.localStorageService.getItem('userLang');
    }

    if (userLang) {
      this.changeLanguage(userLang, true);
    } else {
      this.changeLanguage(environment.defaultLang, true);
    }
  }

  changeLanguage(lang: any, isInitial?: boolean) {
    if(!isInitial) {
      this.googleTagManagerService.sendAnalytics('change_language', {
        from: this.stateService.userLanguage.value,
        to: lang
      });     
    }
    let languageObject = this.availableLanguages.find(
      (obj) => obj?.code === lang
    );
    this.languageControl.patchValue(languageObject)
    this.languageImage = languageObject.primaryImage;
    this.languageCode = languageObject.str
      if(this.isClientPlatform(this.platformId)) {
        this.localStorageService.setItem('userLang', lang);
      }
    this.stateService.setLanguageStatus(lang);
    this.selectedLang = Language[lang];
    let htmlTag = this.document.getElementsByTagName(
      'html'
    )[0] as HTMLHtmlElement;
    if (lang === 'fa' || lang == 'ar') {
      htmlTag.dir = 'rtl';
    } else {
      htmlTag.dir = 'ltr';
    }
    this.changeCssFile(lang);
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    this.closeMenu();
  }

  openOptions(option: string) {
    if (option == 'wallet') {
      this.showBalance = !this.showBalance;
      this.isShowOptions = false;
      this.isProfileOption = false;
    } else if (option == 'language') {
      this.isShowOptions = !this.isShowOptions;
      this.showBalance = false;
      this.isProfileOption = false;
    } else if (option == 'profile') {
      this.isProfileOption = !this.isProfileOption;
      this.isShowOptions = false;
      this.showBalance = false;
    }
  }

  changeCssFile(lang: string) {
    let headTag = this.document.getElementsByTagName(
      'head'
    )[0] as HTMLHeadElement;
    let bundleName;
    if (lang === 'fa' || lang == 'ar') {
      bundleName = './arabicStyle.css';
    } else {
      bundleName = './englishStyle.css';
    }
    let existingLink = this.document.getElementById(
      'langCss'
    ) as HTMLLinkElement;
    if (existingLink) {
      existingLink.href = bundleName;
    } else {
      let newLink = this.document.createElement('link');
      newLink.rel = 'stylesheet';
      newLink.type = 'text/css';
      newLink.id = 'langCss';
      newLink.href = bundleName;
      headTag.appendChild(newLink);
    }
  }

  setLoginStatus() {
    this.stateService.setLoginStatus(this.isClientPlatform(this.platformId) ? this.localStorageService.isLoggedIn : false);
    let subscription = this.stateService
      .getLoginStatus()
      .subscribe((isLoggedIn) => {
        // if (isLoggedIn) {
        //   // this.getWalletBalance();
        //   this.getProfileData();
        // }
        this.isLoggedIn = isLoggedIn;
      });
    // subscription.unsubscribe();
  }

  getStoreDetail() {
    this.storeDetail = this.storeDetailResolver.permissionInit;
  }

  // getStoreGroup() {
  //   this.storeGroups = this.storeGroupResolver.permissionInit?.stores_detail;
  // }

  initRegion() {
    this.filteredCountryList = this.countryList = this.storeRegionService.countryList;
    this.selectedRegion = this.storeRegionService.selectedRegion;
    if(!this.selectedRegion) {
      return;
    }
    this.storeRegionSelect.patchValue(
      this.filteredCountryList.find(item => item.id === this.selectedRegion.id),
      {emitEvent: false}
    )
  }

  // getWalletBalance() {
  //   this.walletBalance.permissionInitInfo.subscribe((data) => {
  //     this.remaining = data;
  //   });
  // }

  // getProfileData() {
  //   this.profileResolver.permissionInitInfo.subscribe((data) => {
  //     this.profileData = data;
  //   });
  // }

  openMenu() {
    this.renderer.addClass(
      this.mobileMenu?.nativeElement,
      'active-mobile-menu'
    );
    this.renderer.addClass(
      this.mobileOverlay.nativeElement,
      'active-mobile-menu'
    );
  }


  openPrefrences() {
    this.prefrencesDialogRef = this.dialog.open(this.prefrencesDialog)
  }

  savePrefrences() {
    this.changeLanguage(this.languageControl.value.code);
    if (this.selectedRegion?.id != this.storeRegionSelect.value.id) {
      this.googleTagManagerService.sendAnalytics('change_region', {
        from: this.selectedRegion.code,
        to: this.storeRegionSelect.value.code
      });
      this.storeRegionService.setRegion(this.storeRegionSelect.value)      
      if(this.router.url.includes('category')) {
        this.router.navigate(
          [], 
          {
            relativeTo: this.activatedRoute,
            queryParams: { country: this.storeRegionSelect.value.id},
            queryParamsHandling: 'merge', // remove to replace all query params by provided
          }
        )
      } else {
        window.location.reload();
      }
      this.selectedRegion = this.storeRegionSelect.value
    }
    this.prefrencesDialogRef.close();
  }

  closeMenu() {
    if (this.mobileMenu) {
      this.renderer.removeClass(
        this.mobileMenu.nativeElement,
        'active-mobile-menu'
      );
    }
    if (this.mobileOverlay) {
      this.renderer.removeClass(
        this.mobileOverlay.nativeElement,
        'active-mobile-menu'
      );
    }
  }

  logOutUser() {
    this.closeMenu();
    this.dialog.open(LogoutDialogComponent);
  }

  isClientPlatform(platformId: Object) {
    if(isPlatformBrowser(platformId)) {
      return true
    } else return false
  }
}
