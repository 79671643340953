import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CookieService } from '../../services/cookie.service';
import { Observable } from 'rxjs';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

declare var FreshworksWidget;
@Component({
  selector: 'app-cookie-settings',
  templateUrl: './cookie-settings.component.html',
  styleUrls: ['./cookie-settings.component.scss']
})
export class CookieSettingsComponent {

  @ViewChild('customSettings') customSettings: any;

  analyticsCookie: boolean = true;
  analyticsMeasurementID: string;
  private isSmallBreakpoint: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]);

  constructor(
    public dialogRef: MatDialogRef<any>,
    private dialogService: MatDialog,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.showFreshworkWidget(false);

    const smallDialogSubscription = this.isSmallBreakpoint.subscribe(size => {
      if (size.matches) {
        this.dialogRef.updateSize('100%');
      } else {
        this.dialogRef.updateSize('500px');
      }
    });

    this.dialogRef.afterClosed().subscribe(() => {
      smallDialogSubscription.unsubscribe();
    });
  }

  acceptAll() {
    this.cookieService.enableGoogleAnalytics();
    this.dialogRef.close();
    this.showFreshworkWidget(true);
    this.cookieService.saveConfig({
      analytics: true,
      isConfirmed: true
    });
    this.dialog.closeAll();
  }

  openSettings() {
    this.dialogService.open(this.customSettings, {disableClose: true, maxWidth: '100vh'})
  }

  toggleCookie() {
    this.analyticsCookie = !this.analyticsCookie;
  }

  confirmCustom() {
    this.analyticsCookie ?
      this.cookieService.enableGoogleAnalytics()
      : this.cookieService.disableGoogleAnalytics();

    
    this.cookieService.saveConfig({
      analytics: this.analyticsCookie,
      isConfirmed: true
    }, true);

    this.dialogRef.close();
  }

  // To Initialize/Destroy the widget (SEE: https://developers.freshdesk.com/widget-api/)
  private showFreshworkWidget(show: boolean) {
    if(window['FreshworksWidget']) {
      FreshworksWidget(show ? 'show' : 'hide');
    }
  }  

}
