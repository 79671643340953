import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationStart, Router, Event, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { StateService } from './modules/shared/services/storage/state.service';
import { HostListener } from '@angular/core'
import { StoreDetailService } from './modules/shared/services/resolver/store-detail.service';
import { Title } from '@angular/platform-browser';
import { AuthenticateService } from './modules/authenticate/services/authenticate.service';
import { LocalStorageService } from './modules/shared/services/storage/local-storage.service';
import { ProfileResolverService } from './modules/shared/services/resolver/profile-resolver.service';
import { DynamicMetaService } from './modules/shared/services/dynamic-meta.service';
import { isPlatformBrowser } from '@angular/common';
import { TitleService } from './modules/shared/services/title.service';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { CookieSettingsComponent } from './modules/shared/components/cookie-settings/cookie-settings.component';
import { CookieService } from './modules/shared/services/cookie.service';

declare var bn;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public showLoading: boolean;
  public isBlock: boolean = false;
  public isOffline: boolean = false;
  storeDetail: any;
  isLoggedIn: boolean = false
  isFirstCall:boolean=true;
  platformId: Object;
  cookieDialogRef: MatDialogRef<any>;
  constructor(
    private stateService: StateService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private storeDetailService: StoreDetailService,
    private titleService: TitleService,
    private authenticateService: AuthenticateService,
    private localStorage: LocalStorageService,
    private profileResolver: ProfileResolverService,
    private dynamicMetaService:DynamicMetaService,
    private cookieService: CookieService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) platFormId: Object
  ) {
    this.platformId = platFormId;
  }

  ngOnInit() {
    this.setLoginStatus()
    this.checkLoading();
    this.checkRoute();
    this.setScripts();
  }

  checkCookieDialog() {
    if(this.cookieDialogRef && this.cookieDialogRef.getState() === MatDialogState.OPEN) return;
    console.log('this is cookie ')
    const showDialog = !this.cookieService.isConfirmed;    
    if(showDialog) {
      console.log('this is show dialog phase')
      this.cookieDialogRef = this.dialog.open(CookieSettingsComponent, {
        disableClose: true,
        hasBackdrop: false,
        maxWidth: '100vh',
        position: { bottom: '0', left: '0' }
      });
    }
  }

  setLoginStatus() {
    this.stateService
      .getLoginStatus()
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
        if(this.isFirstCall){
          this.checkBinanceLogin();
        }
        this.isFirstCall=false;
      });
 
  }

  checkBinanceLogin() {
    if(this.isClientPlatform(this.platformId)) {
      if (window['__NEZHA_BRIDGE__'] && !this.isLoggedIn) {
        let vm = this;
        bn.onMessage = function ({ data }) {
          if (data['action'] == "getUserOpenid") {
            let token = data['payload']['token'];
            if (token) {
              vm.binanceLogin(token)
            }
            return;
          }
        }
  
        bn.miniProgram.postMessage({ action: 'getUserOpenid', payload: { description: 'check user' } })
      }
    }
  }

  binanceLogin(token: any) {
    this.authenticateService.binanceLogin({ token }).subscribe((res) => {
      this.localStorage.setItem('accessToken', res.token);
      this.localStorage.setItem('refreshToken', res.refresh_token);
      this.profileResolver.updateInfo();
      this.stateService.setLoginStatus(true);
    });
  }

  checkLoading() {
    this.stateService.getLoadingStatus().subscribe((loadingStatus) => {
      this.showLoading = loadingStatus;
      this.cdr.detectChanges();
    });
  }

  checkRoute() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.storeDetail = this.storeDetailService.permissionInit
        this.titleService.updateTitle(this.storeDetail?.name)
        this.stateService.startLoading();
      }
      if (
        event instanceof NavigationEnd ||
        event instanceof NavigationError ||
        event instanceof NavigationCancel
      ) {
        this.checkCookieDialog();
        this.stateService.endLoading();
        if (this.router.url.includes('search')) {
          this.stateService.setSearchStatus(true)
        }
        else {
          this.stateService.setSearchStatus(false)
        }
      }
    });
  }

  setScripts(){
    let storeDetail=this.storeDetailService.permissionInit;
    if(storeDetail?.scripts){
      this.dynamicMetaService.loadMeta(storeDetail.scripts);
    }
  }

  isClientPlatform(platformId: Object) {
    if(isPlatformBrowser(platformId)) {
      return true
    } else return false
  }
}
