import { Component, OnInit } from '@angular/core';
import { StateService } from '../../services/storage/state.service';
import { LocalStorageService } from '../../services/storage/local-storage.service';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss']
})
export class MobileNavigationComponent implements OnInit {
  isLoggedIn: boolean = false
  constructor(
    private stateService: StateService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.setLoginStatus();
  }

  setLoginStatus() {
    this.stateService.setLoginStatus(this.localStorageService.isLoggedIn);
    let subscription = this.stateService
      .getLoginStatus()
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      });
  }
}
