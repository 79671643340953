import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { StateService } from './storage/state.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class DynamicScript {
  platformId: Object

  constructor(
    private stateService: StateService,
    @Inject(PLATFORM_ID) platFormId: Object
  ) { 
    this.platformId = platFormId
  }

  loadjQuery() {
   if(this.isClientPlatform(this.platformId) ){
    return new Promise((resolve, reject) => {
      const scriptElement = window.document.createElement('script');
      scriptElement.src = 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js';
      scriptElement.async;
      scriptElement.defer;
      scriptElement.onload = () => {
        resolve("");
      };

      scriptElement.onerror = () => {
        reject();
      };

      window.document.body.appendChild(scriptElement);
    });
   }
  }

  loadrangeSlider() {
    if(this.isClientPlatform(this.platformId) ){
      return new Promise((resolve, reject) => {
        const scriptElement = window.document.createElement('script');
        scriptElement.src = 'https://cdnjs.cloudflare.com/ajax/libs/ion-rangeslider/2.3.1/js/ion.rangeSlider.min.js';
        scriptElement.async;
        scriptElement.defer;
        scriptElement.onload = () => {
          resolve("");
        };
  
        scriptElement.onerror = () => {
          reject();
        };
  
        window.document.body.appendChild(scriptElement);
  
        const link = window.document.createElement('link');
        link.href = 'https://cdnjs.cloudflare.com/ajax/libs/ion-rangeslider/2.3.1/css/ion.rangeSlider.min.css';
        link.rel = 'stylesheet';
        link.type = 'text/css';
        window.document.getElementsByTagName('head')[0].appendChild(link);
      });
    }
  }

  loadSlickCarousel() {
    if(this.isClientPlatform(this.platformId)) {
      return new Promise((resolve, reject) => {
        const link = window.document.createElement('link');
        link.href = '//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick.css';
        link.rel = 'stylesheet';
        link.type = 'text/css';
        window.document.getElementsByTagName('head')[0].appendChild(link);
        const linkSlick = window.document.createElement('link');
        linkSlick.href = '//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick-theme.css';
        linkSlick.rel = 'stylesheet';
        linkSlick.type = 'text/css';
        window.document.getElementsByTagName('head')[0].appendChild(linkSlick);
  
        const scriptElement = window.document.createElement('script');
        scriptElement.src = '//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick.min.js';
        scriptElement.async;
        scriptElement.defer;
        scriptElement.onload = () => {
          resolve("");
        };
  
        scriptElement.onerror = () => {
          reject();
        };
  
        window.document.body.appendChild(scriptElement);
  
      });
    }
  }

  loadGoogleAuth() {
    if(this.isClientPlatform(this.platformId)) {
      this.stateService.startLoading();
      return new Promise((resolve, reject) => {
        const scriptElement = window.document.createElement('script');
        scriptElement.src = 'https://apis.google.com/js/platform.js';
        scriptElement.async;
        scriptElement.defer;
        scriptElement.onload = () => {
          this.stateService.endLoading();
          resolve("");
        };
  
        scriptElement.onerror = () => {
          this.stateService.endLoading();
          reject();
        };
        window.document.body.appendChild(scriptElement);
      });
    }
  }

  loadGoogleOneTap() {
    if(this.isClientPlatform(this.platformId)) {
      this.stateService.startLoading();
      return new Promise((resolve, reject) => {
        const scriptElement = window.document.createElement('script');
        scriptElement.src = 'https://accounts.google.com/gsi/client';
        scriptElement.async;
        scriptElement.defer;
        scriptElement.onload = () => {
          this.stateService.endLoading();
          resolve("");
        };
  
        scriptElement.onerror = () => {
          this.stateService.endLoading();
          reject();
        };
        window.document.body.appendChild(scriptElement);
      });
    }
  }

  loadAppleScript() {
    if(this.isClientPlatform(this.platformId)) {
      this.stateService.startLoading();
      return new Promise((resolve, reject) => {
        const scriptElement = window.document.createElement('script');
        scriptElement.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
        scriptElement.type = 'text/javascript';
        scriptElement.async;
        scriptElement.defer;
        scriptElement.onload = () => {
          this.stateService.endLoading();
          resolve("");
        };
  
        scriptElement.onerror = () => {
          this.stateService.endLoading();
          reject();
        };
        window.document.body.appendChild(scriptElement);
      });
    }
  }

  isClientPlatform(platformId: Object) {
    if(isPlatformBrowser(platformId)) {
      return true
    } else return false
  }
}
