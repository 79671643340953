import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LocalStorageService } from 'src/app/modules/shared/services/storage/local-storage.service';
import { ProfileComponent } from '../profile/profile.component';

@Component({
  selector: 'app-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss']
})
export class LogoutDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ProfileComponent>,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
  }

  logOut() {
    this.localStorageService.logOutUser();
    this.dialogRef.close();
  }
  dontLogout() {
    this.dialogRef.close();
  }
}
