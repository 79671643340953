import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { StoreDetail } from '../../models/store-details.model';
import { StoreDetailService } from '../../services/resolver/store-detail.service';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { LocalStorageService } from '../../services/storage/local-storage.service';
import { Language } from '../../enum/Language';

const FOOTER_STATIC_PAGES = [ 'return-policy', 'cookie-policy', 'privacy-policy' ];
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  storeDetail: StoreDetail;
  copyrightTitle: string;
  copyrightUrl: string;
  emailField = new FormControl('', [Validators.email, Validators.required]);
  staticPages;


  constructor(
    private storeDetailResolver: StoreDetailService,
    private googleTagManager: GoogleTagManagerService,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.getStoreDetail();
  }

  onSubmitEmail() {
  }

  getStoreDetail() {
    this.storeDetail = this.storeDetailResolver?.permissionInit;
    this.staticPages = this.storeDetail.static_pages.filter(obj => 
      FOOTER_STATIC_PAGES.some(str => obj.slug === str)
    );    
    this.copyrightTitle = this.storeDetailResolver?.permissionInit?.copyright_title;
    this.copyrightUrl = this.storeDetailResolver?.permissionInit?.copyright_url;
  }

  onSocialClick(socialClick){
    this.googleTagManager.sendAnalytics('on_social_click',{
      language:Language[this.localStorageService.getItem('userLang')],
      type:socialClick?.label
    })
  }

}
