<div class="navigation-wrapper">
    <div class="mobile-nav">
        <div class="nav-item">
            <a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <img class="icons unselected" src="/assets/images/icons/Icon-house.svg" alt="" />
                <img class="icons icon-selected" src="/assets/images/icons/Icon-house-fill.svg" alt="" />
                <p>{{'nav.home' | translate}}</p>
            </a>
        </div>
        <div class="nav-item">
            <a [routerLink]="['/category']" routerLinkActive="active">
                <img class="icons unselected" src="/assets/images/icons/Icon-grid.svg" alt="" />
                <img class="icons icon-selected" src="/assets/images/icons/Icon-grid-fill.svg" alt="" />
                <p>{{'nav.categories' | translate}}</p>
            </a>
        </div>
        <div class="nav-item">
            <a [routerLink]="['/profile']" [queryParams]="{tab: 'shopSummary'}" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <img class="icons unselected" src="/assets/images/icons/Icon-list.svg" alt="" />
                <img class="icons icon-selected" src="/assets/images/icons/Icon-list-fill.svg" alt="" />
                <p>{{'nav.orders' | translate}}</p>
            </a>
        </div>
        <div class="nav-item">
            <a [routerLink]="['/profile']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                <img class="icons unselected" src="/assets/images/icons/Icon-person.svg" alt="" />
                <img class="icons icon-selected" src="/assets/images/icons/Icon-person-fill.svg" alt="" />
                <p>{{'nav.profile' | translate}}</p>
            </a>
        </div>
    </div>
</div>