<div class="search-form" [ngClass]="{'is-mobile': isMobile, 'is-desktop': !isMobile}">
    <form (submit)="onSearchSubmit()" #searchForm [ngClass]="{'focus-form': !!dialogRef}">
      <div class="input-field">
        <input matInput #searchInput type="text" placeholder="{{'nav.searchPlaceHolder' | translate}}" [formControl]="searchField" (focus)="onFocusSearchField()" />
        <mat-spinner *ngIf="state === searchState.SEARCHING" diameter="15"></mat-spinner>
      </div>
      <button type="submit">
        <img src="/assets/images/icons/search_icon.svg" alt="" />
      </button>
    </form>    
</div>

<ng-template #searchResultDialog>
  <div class="dialog-container">
    <ng-container *ngIf="searchResult && searchResult.length > 0; else noResult">
      <p class="title">Suggested results</p>
      <div class="dialog-main">
        <a class="search-option" *ngFor="let item of searchResult | slice:0:resultLimit"  [routerLink]="[ item.url ]" (click)="onNavigate(item)">
          <img [lazyLoad]="item.logo" [alt]="item.name" defaultImage="assets/images/cards/ic_placeholder.png" class="result-image">
          <p class="result-context">{{ item.name }}</p>
        </a>
      </div>
      <div class="button-container" *ngIf="searchResult.length > resultLimit">
        <button mat-button color="primary" (click)="onSearchSubmit()">See More</button>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #noResult>
  <p class="no-result-context">No results found</p>
</ng-template>