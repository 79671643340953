<footer>
  <div class="main-footer">
    <div class="links">
      <div class="container footer-wrapper">
        <div class="footer-row">
          <div class="logo-desc">
            <img [src]="storeDetail?.logo" [alt]="storeDetail?.name" />
            <p>
              HiGift is an "EZ PIN DMCC" subsidiary that operates in the form of B2C.
            </p>
            <p>
              "EZ PIN DMCC" is a B2B sales e-Gift Card company and has been serving customers since 2020.
              <a [routerLink]="['pages', 'about-us']">Read More</a>
            </p>
            <div class="visa-master">
              <img src="/assets/images/icons/visa-master-2.226fdccdddde.png" alt="" />
            </div>
          </div>
          <div class="nav-links">
            <nav>
              <p class="title">Home</p>
              <a *ngFor="let staticLink of storeDetail?.static_pages"
                [routerLink]="['pages', staticLink.slug]"><span>{{staticLink.title}}</span></a>

            </nav>
          </div>
          <div class="social-media">
            <p class="title">Our Socials</p>
            <a *ngFor="let socialLink of storeDetail?.social_links" target="_blank" [href]="socialLink?.address" (click)="onSocialClick(socialLink)"> <i
                [class]="socialLink?.fontawesome_class"></i><span>{{socialLink.label}}</span></a>
          </div>
          <div class="download">
            <p class="title">Download Our App</p>
            <a href="https://apps.apple.com/us/app/higift/id1536708612" target="_blank">
              <img src="/assets/images/icons/download_on_the_app_store.svg">
            </a>
          </div>
        </div>
        <div class="row">
          <div>
            <a [href]="copyrightUrl" class="copy-right">{{copyrightTitle}}</a>
          </div>
          <div class="more-trees">
            <img src="/assets/images/icons/tree.png">
            <p>{{'footer.tree' | translate}}</p>
          </div>
          <div class="visa-master-mobile">
            <img src="/assets/images/icons/visa-master-2.226fdccdddde.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>