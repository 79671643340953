<div class="main-app">
    <app-navigation></app-navigation>
    <router-outlet></router-outlet>
    <app-footer class="desktop"></app-footer>
    <app-mobile-navigation class="mobile"></app-mobile-navigation>
</div>

<ng-template #storeGroupsDialog>
    <div class="store-groups-container" *ngIf="storeGroups">
        <img class="logo" [src]="storeDetail?.logo" [alt]="storeDetail?.name" />
        <br>
        <p class="title">{{'wrapper.regionSelect' | translate}}:</p>
        <br>
        <div class="countries">
            <div *ngFor="let item of storeGroups">
                <div class="country" (click)="setStoreGroup(item?.client_id)">
                    <div class="image-container">
                        <img [src]="item?.country_detail?.image">
                    </div>
                    <p>{{item?.country_detail?.name}}</p>
                </div>
            </div>
        </div>
        <br>
        <br>
        <button mat-flat-button mat-dialog-close color="primary" (click)="stayOnCurrentPage(storeDetail?.client_id)">
            {{'wrapper.currentPage' | translate}}</button>
    </div>
</ng-template>