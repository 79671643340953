import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from './state.service';
import { isPlatformBrowser } from '@angular/common';
import { TempStorageService } from './temp-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  platformId: Object

  constructor(
    private router: Router,
    private stateService: StateService,
    private tempStorage: TempStorageService,
    @Inject(PLATFORM_ID) platFormId: Object
    ) {
      this.platformId = platFormId;
    }

  getItem(item: string) {
    if(this.isClientPlatform(this.platformId)) {
      try {
        return JSON.parse(window.localStorage.getItem(item));
      } catch (e) {
        return this.tempStorage.getItem(item);
      }
    } else {
      return undefined;
    }
  }

  setItem(item: string, data) {
    if(this.isClientPlatform(this.platformId)) {
      try {
        window.localStorage.setItem(item, JSON.stringify(data));
      } catch (error) {
        this.tempStorage.setItem(item, data);
      }
    }
  }

  deleteItem(item) {
    if(this.isClientPlatform(this.platformId)) {
      try {
        window.localStorage.removeItem(item);
      } catch (error) {
        this.tempStorage.deleteItem(item);
      }
    }
  }

  get isLoggedIn() {
    if(this.isClientPlatform(this.platformId)) {
      try {
        return (
          !!window.localStorage.getItem('accessToken') &&
          !!window.localStorage.getItem('refreshToken')
        );
      } catch (error) {
        return this.tempStorage.isLoggedIn
      }
      
    } else return false
  }

  logOutUser() {
    if(this.isClientPlatform(this.platformId)) {
      try {
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('refreshToken')
      } catch (error) {
        this.tempStorage.logOutUser();
      }
      this.stateService.setLoginStatus(false);
      this.router.navigate(['/authenticate/login']);
    }
  }

  isClientPlatform(platformId: Object) {
    return isPlatformBrowser(platformId);
  }
}
